/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const bannerStyles = makeStyles(() => ({
  bannerSec: {
    paddingTop: 79,
    paddingBottom: 85,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  title: {
    wordBreak: 'keep-all',
  },
}));

export const departmentsStyles = makeStyles(() => ({
  departmentsSec: {
    paddingBottom: 100,
  },
  departmentsSecMobile: {
    paddingBottom: 64,
  },
  title: {
    textAlign: 'center',
    marginBottom: 70,
  },
  titleMobile: {
    marginBottom: '48px !important',
  },
  departmentTitle: {
    marginTop: 10,
    marginBottom: 15,
    minHeight: 80,
  },
  departmentTitleMobile: {
    marginTop: '14px !important',
    marginBottom: '32px !important',
    minHeight: 'auto',
  },
}));

export const itemsStyles = makeStyles(() => ({
  section: {
    paddingBottom: 115,
  },
  title: {
    paddingBottom: 20,
    marginBottom: 24,
    borderBottom: '1px solid #CFC4C5',
  },
  card: {
    minHeight: '340px!important',
  },
  tags: {
    marginRight: -16,
    marginBottom: 54,
  },
  tag: {
    marginBottom: 16,
    marginRight: 16,
  },
  activeTag: {
    backgroundColor: '#EBE6E2!important',
  },
  itemsWithBg: {
    position: 'relative',
    '&::before': {
      content: "' '",
      display: 'block',
      position: 'absolute',
      bottom: -20,
      left: 0,
      right: 0,
      width: '100%',
      height: 100,
      background:
        'linear-gradient(180deg, rgba(250, 250, 250, 0.0001) -74.5%, #FFF 41.44%)',
      zIndex: 1,
      filter: 'drop-shadow(-20px 15px 40px #fff)',
      boxShadow: '0 20px 5px #fff',
      pointerEvents: 'none',
    },
  },
  loadMoreBtn: {
    marginTop: '80px !important',
  },
}));

export const previewStyles = makeStyles(() => ({
  previewSec: {
    paddingTop: 100,
    paddingBottom: 67,
  },
  subtitle: {
    textAlign: 'center',
    marginBottom: 70,
    marginTop: 32,
  },
}));
