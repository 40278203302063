// @flow
//Core
import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import get from 'lodash.get';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';

//Local
import { departmentsStyles } from '../styles';

const DepartmentsSec = ({
  title,
  items = [],
}: {
  title: String,
  items: Array<Object>,
}) => {
  const styles = departmentsStyles();

  const itemsJSX = items.map((item) => (
    <Grid item xs={12} sm={6} md={4} key={get(item, 'sys.id')}>
      <Typography variant="subtitle1">{get(item, 'fields.tag')}</Typography>
      <Typography
        variant="h3"
        className={classNames(
          styles.departmentTitle,
          isMobile && styles.departmentTitleMobile
        )}
      >
        {get(item, 'fields.title')}
      </Typography>
      <Typography variant="body1">{get(item, 'fields.description')}</Typography>
    </Grid>
  ));

  return (
    <section
      className={classNames(
        isMobile ? styles.departmentsSecMobile : styles.departmentsSec
      )}
    >
      <Typography
        variant="h2"
        className={classNames(styles.title, isMobile && styles.titleMobile)}
      >
        {title}
      </Typography>
      <Grid container spacing={isMobile ? 0 : 6}>
        {itemsJSX}
      </Grid>
    </section>
  );
};

export default DepartmentsSec;
