// @flow
//Core
import React from 'react';
import { Typography } from '@material-ui/core';
import formatDate from 'date-format';
//Local
import typography from '../../../../styles/typography.module.scss';
import { Card } from '../../..';
import { previewStyles } from '../styles';
import { filterCardForPublish } from '../../utils';

const PreviewSec = ({
  title,
  subtitle,
  card,
  centeredCardBackground = true,
}: {
  title: string,
  subtitle: string,
  card: Object,
  centeredCardBackground: boolean,
}) => {
  const styles = previewStyles();
  const filteredCard = filterCardForPublish(card);

  return (
    filteredCard && (
      <section className={styles.previewSec}>
        {title && (
          <Typography variant="h2" className={typography.withDividerPurple}>
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography variant="body2" className={styles.subtitle}>
            {subtitle}
          </Typography>
        )}
        <Card
          img={filteredCard && filteredCard.imgUrl}
          title={filteredCard.title}
          content={filteredCard.previewContent}
          tags={filteredCard.tags}
          variant="primary"
          theme="dark"
          size="lg"
          link={filteredCard.url}
          date={formatDate('yyyy/MM/dd', new Date(filteredCard.date))}
          centeredBackground={centeredCardBackground}
        />
      </section>
    )
  );
};

export default PreviewSec;
