//Core
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash.get';
import { Container, Box, CircularProgress } from '@material-ui/core';
//Local
import { Layout } from '../../../components';
import {
  PreviewSec,
  ItemsSec,
  BannerSec,
} from '../../../components/mediaCenterSections/blogCategory';
import * as MediaCenterActions from '../../../redux/mediaCenter/actions';
import { SITE_METADATA } from '../../../constants';

const hashMapping = {
  '#IR': 'IR情報',
  '#kokoku': '電子公告',
};

const MediaCenterFans = ({ location }: { location: Object }) => {
  const pressPage = useSelector((state) => state.mediaCenter.pressPage);

  const { data, isFetching } = useSelector(
    (state) => state.mediaCenter.pressArticles
  );
  const dispatch = useDispatch();
  const pressArticles = data;
  let selectedTag = null;

  // Did mount
  useEffect(() => {
    dispatch(MediaCenterActions.getPressPageAsync());
    dispatch(MediaCenterActions.getPressArticlesAsync());
  }, [dispatch]);

  const tags = get(pressPage, 'tags', []);

  if (location.hash && tags && pressArticles) {
    let tag = tags.find((t) => t.fields.title === hashMapping[location.hash]);

    if (!tag) {
      const title = decodeURIComponent(location.hash.replace('#', ''));

      tag = tags.find((t) => t.fields.title === title);
    }

    selectedTag = get(tag, 'fields.title');
  }

  return (
    <Layout
      isMediaCenter
      SEOProps={{
        title: SITE_METADATA.press.title,
        description: SITE_METADATA.press.description,
      }}
    >
      {isFetching ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          py="100px"
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <>
          <Container maxWidth="md">
            <PreviewSec
              title={get(pressPage, 'pageTitle')}
              subtitle={get(pressPage, 'pageSubtitle')}
              card={{
                ...get(pressPage, 'primaryArticle.fields', {}),
                imgUrl: get(
                  pressPage,
                  'primaryArticle.fields.previewImage.fields.file.url'
                ),
                url: `/media_center/${get(
                  pressPage,
                  'primaryArticle.fields.blogSection.fields.urlKey'
                )}/${get(pressPage, 'primaryArticle.sys.id')}`,
                tags: get(pressPage, 'primaryArticle.fields.tags', []).map(
                  (item) => item.fields.title
                ),
              }}
            />
            <ItemsSec
              title={get(pressPage, 'secondSectionTitle')}
              tags={get(pressPage, 'tags', [])}
              items={pressArticles}
              selectedTag={selectedTag}
            />
          </Container>
          <BannerSec
            title={get(pressPage, 'thirdSectionTitle')}
            background={get(
              pressPage,
              'thirdSectionBackground.fields.file.url'
            )}
            buttonTitle={get(pressPage, 'thirdSectionButtonTitle')}
            buttonUrl={get(pressPage, 'thirdSectionButtonUrl')}
            buttonExtUrl={get(
              pressPage,
              'thirdSectionButtonPdf.fields.file.url'
            )}
          />
        </>
      )}
    </Layout>
  );
};

export default MediaCenterFans;
