// @flow
//Core
import React from 'react';
import { Typography, Button, Container, Grid } from '@material-ui/core';
import { Link } from 'gatsby';
import { bannerStyles } from '../styles';

const BannerSec = ({
  title,
  background,
  buttonTitle,
  buttonUrl,
  buttonExtUrl,
}: {
  title: string,
  background: string,
  buttonTitle: string,
  buttonUrl: string,
  buttonExtUrl?: string,
}) => {
  const styles = bannerStyles();

  return (
    <section
      className={styles.bannerSec}
      style={{ backgroundImage: `url(${background})` }}
    >
      <Container maxWidth="md">
        <Grid container alignItems="center" justify="center" spacing={3}>
          <Grid item xs={4} md={4}>
            <Typography variant="h5" className={styles.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs="auto" md={3}>
            {buttonExtUrl && buttonExtUrl.length && !buttonUrl ? (
              <Button
                component={'a'}
                href={buttonExtUrl}
                variant="text"
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
                className={styles.btn}
              >
                {buttonTitle}
              </Button>
            ) : (
              <Button
                component={Link}
                to={buttonUrl}
                variant="text"
                color="primary"
                className={styles.btn}
              >
                {buttonTitle}
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

BannerSec.defaultProps = {
  buttonExtUrl: '',
};

export default BannerSec;
