// @flow
//Core
import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Box, Typography, Button, Chip } from '@material-ui/core';
import classNames from 'classnames';
import get from 'lodash.get';
import formatDate from 'date-format';
//Local
import typography from '../../../../styles/typography.module.scss';
import { Card } from '../../..';
import { itemsStyles } from '../styles';
import { filterCardsForPublish } from '../../utils';

const ItemsSec = ({
  title,
  tags,
  items: initialItems,
  loadBy = 8,
  handleCustomLoadMore,
  isLoaded: customIsLoaded,
  selectedTag,
  centeredCardBackground,
}: {
  title: string,
  tags: Array<Object>,
  items: Array<Object>,
  loadBy?: number,
  handleCustomLoadMore?: Function,
  isLoaded?: boolean,
  selectedTag?: string,
  centeredCardBackground: boolean,
}) => {
  const styles = itemsStyles({});
  const [items, setItems] = useState(initialItems);
  const [filterBy, setFilterBy] = useState(selectedTag);
  const [loadedCount, setLoadedCount] = useState(loadBy);
  const isLoaded =
    typeof customIsLoaded === 'boolean'
      ? customIsLoaded
      : loadedCount >= initialItems.length;

  const filterItems = filterCardsForPublish(items);

  const handleTagClick = (id, tagTitle) => {
    setFilterBy(id);
    window.location.hash = tagTitle;
  };

  const handleLoadMoreClick = useCallback(() => {
    setLoadedCount(
      loadedCount + loadBy <= initialItems.length
        ? loadedCount + loadBy
        : initialItems.length
    );
  }, [initialItems.length, loadedCount, loadBy]);

  const filterItemsByTag = useCallback(
    (data) =>
      data.filter((item) =>
        filterBy
          ? get(item, 'fields.tags', []).find((tag) => tag.sys.id === filterBy)
          : initialItems
      ),
    [filterBy, initialItems]
  );

  const filterLoadedItems = useCallback((data) => data.slice(0, loadedCount), [
    loadedCount,
  ]);

  useEffect(() => {
    setItems(
      handleCustomLoadMore
        ? filterItemsByTag(initialItems)
        : filterLoadedItems(filterItemsByTag(initialItems))
    );
  }, [
    initialItems,
    filterBy,
    loadedCount,
    handleLoadMoreClick,
    filterItemsByTag,
    filterLoadedItems,
    handleCustomLoadMore,
  ]);

  useEffect(() => {
    const tag = tags.find((t) => t.fields.title === selectedTag);

    if (!tag) {
      setFilterBy(null);
    } else if (tag.sys.id !== filterBy) {
      setFilterBy(tag.sys.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTag, tags, filterBy]);

  const tagsJSX = [
    {
      fields: { title: 'すべての記事' },
      sys: { id: null },
    },
    ...tags,
  ].map((item, index) => (
    <Chip
      label={item.fields.title}
      onClick={() => handleTagClick(item.sys.id, item.fields.title)}
      key={index}
      clickable
      color="secondary"
      className={classNames(styles.tag, {
        [styles.activeTag]: item.sys.id === filterBy,
      })}
    />
  ));

  const itemsJSX = filterItems.map((item, index) => (
    <Grid xs={12} md={6} key={index} item>
      <Card
        img={get(item, 'previewImage.fields.file.url')}
        title={get(item, 'title')}
        tags={get(item, 'tags', []).map((itemm) => get(itemm, 'fields.title'))}
        link={`/media_center/${get(
          item,
          'blogSection.fields.urlKey'
        )}/article/${get(item, 'id')}`}
        variant="primary"
        theme="dark"
        size="md"
        className={styles.card}
        date={formatDate('yyyy/MM/dd', new Date(get(item, 'date')))}
        centeredBackground={centeredCardBackground}
      />
    </Grid>
  ));

  return (
    <section className={styles.section}>
      <Typography
        className={classNames(styles.title, typography.withDividerLeftPurple)}
        variant="body1"
      >
        {title}
      </Typography>
      <Box className={styles.tags}>{tagsJSX}</Box>
      <Grid
        container
        spacing={6}
        className={classNames({
          [styles.itemsWithBg]: !isLoaded,
        })}
      >
        {itemsJSX}
      </Grid>
      {!isLoaded && (
        <Box textAlign="center">
          <Button
            className={styles.loadMoreBtn}
            variant="outlined"
            color="primary"
            onClick={handleCustomLoadMore || handleLoadMoreClick}
          >
            表示を増やす
          </Button>
        </Box>
      )}
    </section>
  );
};

ItemsSec.defaultProps = {
  items: [],
  handleCustomLoadMore: undefined,
  isLoaded: undefined,
  loadBy: 8,
  selectedTag: null,
  centeredCardBackground: true,
};

export default ItemsSec;
